<template>
  <div class="flex justify-content-center my-8">
    <BaseCard class="card--fill-space w-12 card--register">
      <template #title>
        <BaseRow :no-gutter="true" class="justify-content-between">
          <BaseCol cols="11" class="flex align-items-center mb-3">
            <BaseHeadline size="6" no-margin class="text-primary">
              {{ $t("stp.teacher.register.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol class="flex flex-grow-0 align-items-center align-content-end mb-3">
            <BaseButton
              iconLeft="pi pi-times"
              rounded
              text
              color="gc-color-danger"
              @click="goToStartPage"
            />
          </BaseCol>
        </BaseRow>
      </template>

      <BaseRow :no-gutter="true">
        <BaseCol cols="12">
          <BaseMessage v-if="showSuccessMessage" severity="success" :closable="false">
            {{ $t("stp.register.message.success") }}
          </BaseMessage>

          <template v-else>
            <AuthRegisterForm v-if="isAuthenticated" />
            <RegisterForm v-else @success="showSuccess" :invitationToken="invitationToken" />
          </template>
        </BaseCol>
      </BaseRow>
      <BaseRow :no-gutter="true" class="justify-content-center">
        <BaseCol cols="6" class="text-center">
          <BaseDivider />
          <BaseText size="xs">{{ $t("stp.register.label.login") }}</BaseText>
          <BaseText size="xs">
            <BaseLink :to="{ name: 'Login' }">{{ $t("stp.register.link.login") }}</BaseLink>
          </BaseText>
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </div>
</template>

<script>
import { ref } from "vue";
import RegisterForm from "./partials/RegisterForm.vue";
import { useRoute, useRouter } from "vue-router";
import AuthRegisterForm from "./partials/AuthRegisterForm";
import { useStore } from "vuex";

export default {
  name: "Register",

  components: {
    AuthRegisterForm,
    RegisterForm,
  },

  setup() {
    const showSuccessMessage = ref(false);

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const invitationToken = route.query.token;

    const isAuthenticated = store.getters["usm/auth/isAuthenticated"];
    const user = store.getters["usm/authUser/user"];

    const goToStartPage = async () => {
      try {
        await router.push({ name: "TeacherStartPage" });
      } catch {
        return;
      }
    };

    const showSuccess = () => {
      showSuccessMessage.value = true;
    };

    if (user && user.teacher) {
      goToStartPage();
    }

    return {
      showSuccessMessage,
      goToStartPage,
      invitationToken,
      showSuccess,
      isAuthenticated,
    };
  },
};
</script>

<style lang="scss" scoped>
.card--register {
  max-width: 50rem;
}
</style>
