<template>
  <BaseForm
    @submit.prevent="register"
    :validation="v$"
    :message="bvMessage"
    :error="bvHasErrors"
    hideMandatory
  >
    <BaseRow>
      <BaseCol cols="4" class="py-0">
        <BaseTextField
          v-model="formValues.salutation"
          label="stp.register.form.label.salutation"
          placeholder="stp.register.form.placeholder.salutation"
          :validation="v$.salutation"
          :messages="bvShowErrors('salutation').messages"
          :error="bvShowErrors('salutation').hasMessages"
          :maxLength="maxLength.USERS.SALUTATION"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="4" class="py-0">
        <BaseTextField
          v-model="formValues.title"
          label="stp.register.form.label.title"
          placeholder="stp.register.form.placeholder.title"
          :validation="v$.title"
          :messages="bvShowErrors('title').messages"
          :error="bvShowErrors('title').hasMessages"
          :maxLength="maxLength.USERS.TITLE"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="4" class="py-0">
        <BaseTextField
          v-model="formValues.firstName"
          label="stp.register.form.label.firstName"
          placeholder="stp.register.form.placeholder.firstName"
          :validation="v$.firstName"
          :messages="bvShowErrors('firstName').messages"
          :error="bvShowErrors('firstName').hasMessages"
          :maxLength="maxLength.USERS.FIRST_NAME"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="4" class="py-0">
        <BaseTextField
          v-model="formValues.lastName"
          label="stp.register.form.label.lastName"
          placeholder="stp.register.form.placeholder.lastName"
          :validation="v$.lastName"
          :messages="bvShowErrors('lastName').messages"
          :error="bvShowErrors('lastName').hasMessages"
          :maxLength="maxLength.USERS.LAST_NAME"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="4" class="py-0">
        <BaseTextField
          v-model="formValues.email"
          label="stp.register.form.label.email"
          placeholder="stp.register.form.placeholder.email"
          :validation="v$.email"
          :messages="bvShowErrors('email').messages"
          :error="bvShowErrors('email').hasMessages"
          :maxLength="maxLength.USERS.EMAIL"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="12">
        <PasswordCreate
          v-model:password="formValues.password"
          v-model:passwordConfirm="formValues.passwordConfirmation"
          :passwordValidation="v$.password"
          :passwordMessages="bvShowErrors('password').messages"
          :passwordError="bvShowErrors('password').hasMessages"
          :passwordConfirmValidation="v$.passwordConfirmation"
          :passwordConfirmMessages="bvShowErrors('passwordConfirmation').messages"
          :passwordConfirmError="bvShowErrors('passwordConfirmation').hasMessages"
          :floatingLabels="true"
          :horizontalHints="true"
          cols="4"
          @complete="completePassword($event)"
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="12" class="py-0">
        <BaseTextArea
          v-model="formValues.shortApplication"
          label="stp.register.form.label.shortApplication"
          placeholder="stp.register.form.placeholder.shortApplication"
          :validation="v$.shortApplication"
          :messages="bvShowErrors('shortApplication').messages"
          :error="bvShowErrors('shortApplication').hasMessages"
          floatingLabel
          rows="10"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseRow class="justify-content-center mt-4">
        <BaseCol cols="6" class="text-center">
          <BaseButton
            type="submit"
            label="stp.register.form.label.btn"
            fullWidth
            :loading="isLoading"
          />
        </BaseCol>
      </BaseRow>
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import useBackendValidation from "@use/useBackendValidation";
import { reactive, ref, watch } from "vue";
import PasswordCreate from "@shared/FormElements/PasswordCreate.vue";
import nullifyObject from "../../../../../utilities-utls/helper/nullifyObject";
import BaseTextArea from "../../../../../base-components-bc/Base/BaseTextArea";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";
import useApi from "@use/useApi";
import useNotifications from "@use/useNotifications";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "RegisterForm",

  components: {
    BaseTextArea,
    PasswordCreate,
  },

  props: {
    invitationToken: {
      type: String,
      required: false,
    },
  },

  setup(props, { emit }) {
    const form = reactive({
      invitationToken: props.invitationToken,
      email: null,
      salutation: null,
      title: null,
      firstName: null,
      lastName: null,
      password: null,
      passwordConfirmation: null,
      shortApplication: null,
    });

    const rules = {
      invitationToken: [],
      email: ["required", "email"],
      salutation: ["required"],
      title: [],
      firstName: ["required"],
      lastName: ["required"],
      password: ["required"],
      passwordConfirmation: ["required"],
      shortApplication: [],
    };

    const store = useStore();
    const router = useRouter();
    const { showSuccess, showError } = useNotifications();
    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const isComplete = ref(false);
    const completePassword = (event) => {
      isComplete.value = event;
    };

    const { callApi, isLoading, docs, backendValidation } = useApi({
      module: "tm",
      method: "register",
      onSuccess: async () => {
        showSuccess("stp.register.notify.success.summary", "stp.register.notify.success.detail");

        if (props.invitationToken) {
          await store.dispatch("usm/auth/saveToken", {
            accessToken: docs.value.token.access_token,
            refreshToken: docs.value.token.refresh_token,
          });
          await router.push("/");
        } else {
          emit("success");
        }
      },
      onError: () => showError(backendValidation.value.message, backendValidation.value.message),
    });

    const register = () => {
      v$.value.$touch();
      if (v$.value.$error || !isComplete.value || isLoading.value) return;
      callApi(nullifyObject(formValues));
    };

    const { updateValidation, bvMessage, bvHasErrors, bvShowErrors } = useBackendValidation();

    watch(backendValidation, () => {
      updateValidation(backendValidation.value);
    });

    return {
      v$,
      formValues,
      register,
      bvMessage,
      bvHasErrors,
      bvShowErrors,
      completePassword,
      maxLength,
      isLoading,
    };
  },
};
</script>
